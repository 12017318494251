@font-face {
  font-family: "FTPD";
  src: local("FTPD"), url(../../Fonts/FuturaPTDemi.otf) format("opentype");
}

@font-face {
  font-family: "fb";
  src: local("fb"), url(../../Fonts/FuturaPTBold.otf) format("opentype");
}

@font-face {
  font-family: "fm";
  src: local("fm"), url(../../Fonts/FuturaPTMedium.otf) format("opentype");
}

@font-face {
  font-family: "fl";
  src: local("fl"), url(../../Fonts/FuturaPTLight.otf) format("opentype");
}

body {
  overflow-x: hidden;
}
.modal_head_txt {
  font-family: "fb";
  font-style: normal;
  font-weight: 800;
  font-size: 22px;
  line-height: 30px;
  color: #000000;
}

.modal_sub_txt {
  font-family: "fm";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  color: #919191;
}

/* Landing Nav Bar */
.div_overflow {
  overflow: hidden;
}
.loader {
  height: 20px;
  width: 20px;
}
/* .loader {
  height: 20px;
  width: 20px;
} */
.nav_container {
  padding-left: 6%;
  padding-right: 6%;
}

.cendrol_log {
  width: 148px;
  height: 53px;
}

.navbar-brand img {
  width: 150px;
}

.nav_mail_icons {
  color: #fdc315;
}
.nav_whatapp {
  color: #00e676;
}

.land_nav_ul li a {
  display: flex;
  gap: 10px;
  text-decoration: none;
  font-family: "FTPD";
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 28px;
  color: #000000;
}

.land_nav_ul > li + li {
  border-left: solid 1px #0a0a0a;
}

.btn_get_free_quote {
  background: linear-gradient(92.68deg, #ffe185 -18.4%, #f7ba00 113.86%);
  border-radius: 5px;
  font-family: "FTPD";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 26px;
  color: #000000;
  border: none;
}

.btn_get_free_quote:focus {
  box-shadow: none;
}

.btn_get_free_quote:hover {
  color: #000000;
}

/* Present in */
.land_present {
  height: 310px;
  background: #fafafa;
  margin-top: 28%;
}
.land_present_in_txt {
  font-family: "FTPD";
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 41px;
  color: #000000 !important;
}

.land_cols_present {
  margin-bottom: 1rem !important;
}

.land_present_arrow_btn {
  background: #0a0a0a;
  border-radius: 2px;
  color: #ffffff;
  border: none;
  width: 42px;
  height: 36px;
}

.land_arrow_icon {
  font-size: 25px;
}

.land_present_arrow_btn:hover {
  background: #fdc315;
  color: #0a0a0a;
}

.land_present_img {
  background: #ffffff;
  border-radius: 7px;
  text-align: center;
  /* height: 120px; */
}

.land_present_img img {
  max-height: 80px;
  max-width: 150px;
}

/* Customer Feedback */
.land_cust_feed_head_txt {
  font-family: "fb";
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 1;
  color: #000000;
  text-transform: uppercase;
}
.cust_love_icon {
  font-size: 40px !important;
  color: red;
  vertical-align: bottom !important;
}

.land_cust_feed_sub_txt {
  font-family: "fm";
  font-style: normal;
  font-weight: 450;
  font-size: 20px;
  line-height: 26px;
  color: #969595;
  padding-bottom: 70px;
}

.mainHappyCustomerBox {
  background-color: #fff;
  border-radius: 10px;
  padding: 25px;
  text-align: center;
  /* margin-bottom: 20px; */
  position: relative;
  box-shadow: 0px 0px 20px rgb(0 0 0 / 7%);
}

.mainHappyCustomerBox .customerImage {
  display: flex;
  justify-content: center;
  padding-bottom: 30px;
}

.mainHappyCustomerBox .customerImage img {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  border: solid 3px #fff;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
  object-fit: cover;
}

.mainHappyCustomerBox p {
  font-family: "fm";
  font-style: normal;
  font-weight: 450;
  font-size: 16px;
  line-height: 21px;
  text-align: center;
  color: #969595;
  min-height: 120px;
  margin-bottom: 0px;
}

.land_cust_feedback {
  padding-top: 30px;
}

.land_cust_line {
  height: 3px;
  width: 20px;
  background-color: #fdc315;
}

.mainHappyCustomerBox h4 {
  color: #040404;
  font-size: 20px;
  font-weight: 600;
  font-family: "fb";
  padding-top: 30px;
}

.mainHappyCustomerBox h5 {
  color: #040404;
  font-size: 13px;
  font-weight: bold;
  font-family: "fb";
  margin-bottom: 0px;
}

.land_feedback_arrow_prev,
.land_feedback_arrow_next {
  position: absolute;
  top: 50%;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 3%;
  padding: 0;
  height: 40px;
  text-align: center;
  border: 0;
  /* opacity: 0.5; */
  transition: opacity 0.15s ease;
  background: #000000;
  color: #fff;
  border-radius: 2px;
}

.land_feedback_arrow_next {
  right: 0px;
}

.land_feedback_arrow_next:hover {
  background: #fdc315;
  color: #0a0a0a;
}

.land_feedback_arrow_prev:hover {
  background: #fdc315;
  color: #0a0a0a;
}

/* FAQ */
.land_faq_head_txt {
  color: #040404;
  font-size: 45px;
  font-weight: bold;
  margin-bottom: 20px;
  line-height: 1;
  text-transform: uppercase;
  font-family: "fb";
  padding-bottom: 3rem;
  font-style: normal;
}

/* .accordion {
  padding-bottom: 50px;
} */
.accordion .card {
  margin-bottom: 25px;
  border: none;
}
.accordion .card-body {
  margin-top: 20px;
  font-weight: 400;
  font-size: 18px;
  border-radius: 25px;
  position: relative;
  padding: 30px 35px 10px 35px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.05);
  color: #969595;
  font-family: "fm";
  line-height: 1.5;
}
.accordion .card-body:after,
.accordion .card-body:before {
  width: 4px;
  height: 20px;
  content: "";
  bottom: 100%;
  position: absolute;
  background-color: #fdc315;
}
.accordion .card-body:after {
  left: 70px;
}
.accordion .card-body:before {
  right: 70px;
}
.accordion .card-header {
  border-radius: 0px !important;
  background-color: transparent;
  border: none;
  padding: 0px;
}
.accordion .card-header button {
  display: block;
  position: relative;
  font-size: 21px;
  font-family: "fm";
  text-decoration: none;
  color: #333333;
  padding: 20px 80px 20px 35px;
  background-color: #fdc315;
  border-radius: 40px !important;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.05);
  width: 100%;
  border: none;
  text-align: start;
}

.accordion .card-header button.collapsed {
  background-color: #fff;
}

.accordion button .icon {
  position: absolute;
  width: 28px;
  height: 28px;
  border-radius: 22px;
  background: #fff;
  right: 20px;
  top: 30%;
  color: #000;
  font-size: 13px;
  border-radius: 50%;
  font-weight: 900;
  text-align: center;
  line-height: 30px;
}

.accordion .collapsed .icon {
  background: #fdc315;
}

.accordion .icon {
  background: #fdc315;
}

.accordion button .icon::before {
  position: absolute;
  content: "";
  top: 13px;
  left: 9px;
  width: 10px;
  height: 2px;
  background: currentColor;
}
.accordion button .icon::after {
  position: absolute;
  content: "";
  top: 9px;
  left: 13px;
  width: 2px;
  height: 10px;
  background: currentColor;
}

.accordion button[aria-expanded="true"] .icon::after {
  width: 0;
}

/* Footer */
.footer {
  color: #666;
  font-size: 18px;
  font-weight: 500;
  background-color: #f7f7f7;
}
.rowMainFooter {
  padding: 65px 0px;
}
.boxCopyright {
  text-align: center;
  color: #000;
  font-size: 16px;
  line-height: 1;
  font-weight: 500;
  padding: 24px 0px;
  background-color: #ececec;
}

.boxCopyright p {
  font-family: "fm";
}

.footerLogo {
  width: 150px;
  margin-bottom: 30px;
}
.footerLogo img {
  width: 100%;
}
.rowMainFooter h4 {
  text-transform: none;
  color: #000;
  padding-bottom: 12px;
  font-family: "fb";
  font-size: 26px;
  font-weight: 600;
  position: relative;
  margin-bottom: 20px;
}
.rowMainFooter h4:after {
  width: 40px;
  height: 4px;
  bottom: 0px;
  left: 0px;
  content: "";
  position: absolute;
  background-color: #fdc315;
}

ul.socialIcons li a:hover {
  color: #fdc315;
}
ul.socialIcons {
  font-size: 20px;
  list-style: none;
  padding-left: 0px;
}
ul.socialIcons li {
  display: inline-block;
}
ul.socialIcons li + li {
  margin-left: 20px;
}
ul.socialIcons li a {
  color: #000;
}

ul.listContactInfo {
  list-style: none;
  padding-left: 0px;
}
ul.listContactInfo li:first-child {
  border-top: none;
}
ul.listContactInfo li {
  position: relative;
  padding-left: 33px;
  padding-top: 20px;
  margin-top: 15px;
  border-top: solid 1px #cbcbcb;
}
ul.listContactInfo li a {
  color: #000;
  text-decoration: none;
  font-family: "fm";
}
ul.listContactInfo li a:hover {
  color: #fdc315;
}

.land_li_icons {
  position: absolute;
  left: 0px;
  color: #fdc315;
  top: 23px;
}

/* user data form */
.form_head_txt {
  font-family: "FTPD";
  font-size: 25px;
  line-height: 29px;
  color: #000000;
}

.user_form_label {
  font-family: "fm";
  font-size: 14px;
  line-height: 16px;
  color: #000000;
  padding-bottom: 10px;
}

.user_form_fileds {
  background: #f2f2f2;
  border-radius: 2px;
  padding: 15px;
  font-family: "fm";
  font-size: 15px;
  line-height: 18px;
  color: #0e0e0e;
}

.number_edit_91 {
  display: flex;
  align-items: center;
  padding: 10px;
  text-align: center;
  white-space: nowrap;
  font-family: "FTPD";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
  background: #ffe596;
  border-radius: 1px 0px 0px 1px;
}

.form-control:focus {
  background: #ffffff;
  border: 1px solid #fcc216;
  box-sizing: border-box;
  box-shadow: 0px 1px 9px rgba(252, 194, 22, 0.4);
}

.form_OTP_not,
.resend_otp {
  font-family: "fm";
  font-size: 14px;
  line-height: 16px;
  color: #969696;
}

.rs_symbol {
  font-family: system-ui !important;
}

/* #email {
  text-transform: lowercase;
} */

#user_otp,
#btn_signup {
  display: none;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.resend_otp {
  color: #fcc314;
  cursor: pointer;
  pointer-events: none;
}

.form_signup_btn {
  background: linear-gradient(91.92deg, #ffe185 -10.45%, #f7ba00 115.97%);
  border-radius: 5px;
  padding: 12px;
  width: 100%;
  font-family: "fm";
  font-size: 15px;
  line-height: 18px;
  color: #000000;
  /* pointer-events: none; */
  /* display: none; */
  border: none;
}

.btn:focus {
  box-shadow: none !important;
}

.otp_sent,
.otp_worong {
  font-family: "fm";
  font-size: 15px;
  color: green;
}

.otp_worong {
  color: red;
}

.errors {
  color: red !important;
}

/* Darshan */
/* button */
.mob_cal_btn {
  display: none;
  background: #fdc315;
  border-radius: 5px;
  border: none;
  padding: 10px 20px;
  font-family: FTPD;
}

/* first section */
.sliding_comp {
  margin-top: 6%;
  position: relative;
}
.overlap_section {
  width: 100%;
  z-index: 10;
  position: absolute;
  /* bottom: 145px; */
  top: 50%;
  color: #fff;
}
.overlap_section2 {
  z-index: 10;
  position: absolute;
  margin-left: auto;
  color: #fff;
}

.carousel_txt {
  width: 48%;
  right: auto;
  left: 25px;
  text-align: left;
  bottom: "20%";
  color: #fff;
}

.text_overlap {
  background: rgba(9, 9, 9, 0.2);
  border: 1px solid #ffffff;
  box-sizing: border-box;
  backdrop-filter: blur(9px);
  /* Note: backdrop-filter has minimal browser support */
  border-radius: 10px;
  font-family: "fb";
  font-style: normal;
  font-weight: 700;
  font-size: 47.156px;
  line-height: 60px;
  color: #ffffff;
  padding: 22px;
  text-align: center;
}
.sectionVideo .containerForm {
  top: 50%;
  left: 50%;
  z-index: 12;
  position: absolute;
  transform: translate(-50%, -50%);
}

/* .form-control {
  height: 42px;
  font-size: 16px;
  border-radius: 0px;
  color: #333333;
  padding: 3px 0px;
  background-color: transparent !important;
  box-shadow: none !important;
  border-color: #d1d1d1;
  border-width: 0px 0px 1px 0px;
} */

.verify_n_proceed {
  background: #fcc314;
  border-radius: 5px;
  border: none;
  padding: 14px;
}
.form-group {
  margin-bottom: 20px;
  position: relative;
}
.first_sec_form_div {
  display: block;
}

#carouselExampleSlidesOnly1 {
  display: none;
  background: #000;
  color: #fff;
  padding: 10px;
  text-align: center;
}

/* People choose */
.why_ppl_choose {
  padding: 55px;
}

.rowPeoplechoose .contentChoose {
  max-width: 300px;
  margin-left: auto;
  margin-right: auto;
}
.rowPeoplechoose .iconPeople {
  display: table;
  position: relative;
  z-index: 1;
  padding-top: 24px;
  margin: 0px auto 30px auto;
}
.rowPeoplechoose .iconPeople {
  display: table;
  position: relative;
  z-index: 1;
  padding-top: 24px;
  margin: 0px auto 30px auto;
}
.rowPeoplechoose .iconPeople:after {
  width: 90px;
  height: 90px;
  left: -30px;
  top: 0px;
  content: "";
  position: absolute;
  border-radius: 50%;
  z-index: -1;
  background-color: #fedc75;
}
.rowPeoplechoose h6 {
  font-size: 20px;
  color: #000;
  font-weight: bold;
  font-family: fm;
}
.rowPeoplechoose {
  text-align: center;
}
.rowPeoplechoose .row + .row [class*="col"] .contentChoose {
  padding-top: 60px;
  margin-top: 40px;
  border-top: solid 1px #e3e1e1;
}
.sectionComman.sectionPeople {
  padding: 70px 0px;
}
.sectionCommanInner.sectionHappyCustomerInner {
  padding: 70px 0px;
}

/* strip */
.bgYellowDesign {
  background-color: #fdc315;
  padding: 60px 0px;
}
.btn:hover {
  background-color: #000 !important;
  color: #fff !important;
  transform: scale(1.06);
  box-shadow: 0px 0px 30px rgb(0 0 0 / 20%);
}
.btn,
input[type="submit"] {
  transition: ease-in-out 0.4s;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.11);
}
.btnWhite {
  background-color: #fff !important;
  color: #000 !important;
  height: 80px !important;
  line-height: 80px !important;
  font-family: fb !important;
  padding: 0px 80px !important;
  font-size: 18px !important;
}
.designBuilding h4 {
  font-size: 40px;
  font-family: fb;
  color: #000;
  margin: 0px;
}

/* projects section */
.boxLoadMore {
  padding-top: 40px;
}
a {
  transition: ease-in-out 0.4s;
}
.heading-center {
  text-align: center;
}
h2 {
  color: #040404;
  font-size: 45px;
  font-weight: bold;
  margin-bottom: 20px;
  line-height: 1;
  font-family: fb;
  text-transform: uppercase;
}
.btn,
input[type="submit"] {
  font-weight: bold;
  border-radius: 0px;
  font-size: 15px;
  min-width: 190px;
  height: 43px;
  color: #000000;
  border-radius: 5px;
  padding: 0px 20px;
  line-height: 43px;
  text-transform: uppercase;
  border: none;
  cursor: pointer;
  transform: scale(1);
  background-color: #fdc315;
  transition: ease-in-out 0.4s;
  box-shadow: 0px 0px 20px rgb(0 0 0 / 11%);
}
.contentHomeDesign:hover {
  transition: 0.5s ease-in-out;
  /* transform: scale(1.18); */
}
.contentHomeDesign .imgDesign img {
  width: 100%;
  height: 230px;
  object-fit: cover;
  border-radius: 10px;
  box-shadow: 0px 0px 20px rgb(0 0 0 / 20%);
}
.contentHomeDesign .imgDesign {
  margin-bottom: 23px;
}
.sub-heading {
  font-size: 20px;
  color: #969595;
  margin-bottom: 70px;
  text-align: center;
  font-family: fm;
}
h4 {
  color: #040404;
  font-size: 20px;
  font-weight: 600;
  font-family: FTPD;
  margin-bottom: 15px;
}
h2 {
  color: #040404;
  font-size: 45px;
  font-weight: bold;
  margin-bottom: 20px;
  line-height: 1;
  font-family: fb;
  text-transform: uppercase;
}
.sizeDetail .row [class*="col"]:first-child {
  padding-right: 5px;
}
.sizeDetail .row [class*="col"] + [class*="col"] {
  border-left: solid 1px #e3e1e1;
  padding-left: 15px;
  padding-right: 10px;
}
.sizeDetail .btn {
  width: 100%;
  min-width: inherit;
  padding: 0px 5px;
  font-size: 11px;
  height: 37px;
  line-height: 37px;
  background-color: #fdc315;
  font-family: "fb";
}
.contentHomeDesign h5 {
  font-weight: bold;
  font-family: fb;
  font-size: 14.5px;
  color: #000000;
  margin-bottom: 0px;
}
.contentHomeDesign h6 {
  font-size: 14px !important;
  color: #939090;
  margin-bottom: 2px;
  font-family: "fm";
}
.sizeDetail .row + .row {
  margin-top: 20px;
}
.contentHomeDesign .designDetail {
  border-radius: 10px;
  padding: 17px;
  background-color: #fff;
  box-shadow: 0px 0px 20px rgb(0 0 0 / 10%);
}
.btnLoadMore {
  font-family: fb;
  text-align: center;
  padding: 95px 0px 0px 0px;
}
.boxLoadMore > .row + .row {
  margin-top: 40px;
}
.arows_ {
  margin-left: 5px;
}

/* ?Packages */
.accordion-header {
  cursor: pointer;
}
.sub-heading1 {
  font-size: 20px;
  color: #969595;
  /* margin-bottom: 70px; */
  text-align: center;
  font-family: fm;
}

.sub-heading2 {
  font-size: 13px;
  color: #969595;
  margin-bottom: 0px;
  font-family: fm;
}
.package_city {
  color: #fdc315;
}
.package_inner_divs {
  text-align: left;
  background: #fff;
  padding: 18px;
}
.pack_first_card {
  /* border-bottom: 1px solid #e2e2e2; */
  /* padding-top: 10px; */
  display: flex;
  align-items: center;
  padding: 6px;
  padding-bottom: 16px;
}
.collapse_strips {
  padding: 10px;
  border-bottom: none;
}
.accordion-item {
  background-color: #fff;
  border: none;
}
.pack_headings {
  font-family: FTPD;
  font-size: 27px;
}
.package_collapse_icon {
  color: #fdc315;
  cursor: pointer;
}
.design_points {
  font-family: fm;
  margin-top: 10px;
  line-height: 2rem;
}
.design_points li {
  list-style-image: url(../../Assests/Images/tik.svg);
}
.first_comp_heading {
  font-family: fm;
}
.right_tick {
  color: #3add5e;
  margin-right: 5px;
}
.collapse_strips {
  padding: 10px;
  /* border-bottom: 1px solid lightgray; */
}
.accordion-flush .accordion-item {
  border-right: 0;
  border-top: 1px solid #e2e2e2;
  border-left: 0;
  border-radius: 0;
}
/* Custom designing black */
.customdesign_banner .btn {
  height: 72px;
  font-size: 18px;
  line-height: 72px;
  padding: 0px 40px;
}
.customdesign_banner {
  padding: 80px 0px;
  text-align: center;
  background-color: #000;
}
.customdesign_banner .btn:hover {
  background-color: #ffff !important;
  color: #000000 !important;
}
.customdesign_banner h3 {
  color: #fff;
  font-size: 40px;
  font-family: fb;
  font-weight: bold;
  padding-bottom: 45px;
}
.customdesign_banner h3 {
  margin: 0px;
  font-weight: normal;
  text-transform: none;
  text-align: center;
}

/* Cost Calaculator */
.cal_logo {
  width: 22px;
}
.talk-bubble {
  margin: 0px;
  padding: 3px;
  display: inline-block;
  position: absolute;
  width: 117px;
  height: 27px;
  background-color: #49f046;
  border-radius: 3px !important;
  top: 63%;
  right: 15%;
  text-align: center;
}
.cost_cal_recommended {
  text-shadow: 1px 1px 10px lightslategrey;
  color: #000000;
  font-family: FTPD;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
}
.tri-right.btm-left:after {
  content: " ";
  position: absolute;
  width: 15px;
  height: 15px;
  left: 20px;
  right: auto;
  top: auto;
  bottom: -10px;
  border: 10px solid;
  border-color: transparent transparent transparent #49f046;
  transform: matrix(1, 0, 0, -1, 0, 0);
  border-radius: 1px;
  /* z-index: -1; */
}
.background_all {
  width: 50px;
  height: 50px;
  background: #f4f4f4;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.cost_cal_packages_select {
  border: none;
  background: #f4f4f4;
  padding: 7px 12px;
  font-size: 14px;
  border-radius: 3.64362px;
  font-family: FTPD;
}

.span_SBUA {
  font-family: fm;
  font-style: normal;
  font-weight: 450;
  font-size: 10px;
  color: #000000;
}
.quantity__minus,
.quantity__plus {
  display: block;
  width: 22px;
  height: 23px;
  margin: 0;
  color: #000 !important;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 40px !important;
  border: 1px solid #000;
  background: #fff;
  text-decoration: none;
  text-align: center;
  line-height: 23px;
}
.cost_cal_input_fields {
  width: 100%;
  background: #f4f4f4;
  border-radius: 5px;
  border: none;
  padding: 10px;
}
.quantity__input {
  width: 32px;
  height: 19px;
  margin: 0;
  padding: 0;
  text-align: center;
  border: none;
  background: #fff;
  color: #8184a1;
}
.quantity__input2 {
  width: 32px;
  height: 19px;
  margin: 0;
  padding: 0;
  text-align: center;
  border: none;
  background: #fff;
  color: #8184a1;
}
.quantity {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
}
.cost_cal_heading {
  font-size: 30px;
  font-family: fb;
}
.modal-header {
  border-bottom: none;
}

.popup_details {
  margin-bottom: 0px !important;
}

.cal_btn {
  background: #fdc315;
  border-radius: 5px;
  border: none;
  padding: 10px 20px;
  font-family: FTPD;
}

/* Updation of Cost Caluclator start */
.total_cost {
  font-family: "FTPD";
  font-style: normal;
  font-weight: 600;
  font-size: 30.3448px;
  line-height: 39px;
  color: #000000;
}

.heading_cost_cal {
  font-family: "fb";
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 51px;
  color: #0a0a0a;
}

.first_sec_form {
  background: #ffffff;
  box-shadow: 0px 5px 39px -3px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  position: absolute;
  width: 40%;
  z-index: 12;
  top: 50px;
  right: 30px;
  font-family: "fm";
  padding: 21px;
}

.input-wrapper {
  position: relative;
  display: flex;
  background-color: #f4f4f4;
  border-radius: 5px;
  padding: 10px;
  justify-content: space-between;
}

.number_field {
  border: none;
  font-family: "FTPD";
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 17px;
  color: #000000 !important;
  background: none !important;
  /* margin-left: 30px;
    margin-top: 10px; */
}

.calculated_value {
  align-self: center;
}

.btn_get_quote {
  width: 100%;
  border: none;
  /* display: none; */
  background: #fdc315;
  border-radius: 4.09091px;
  font-family: "fm";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 25px;
  color: #0a0a0a;
  text-transform: none !important;
}

#btn_verify_otp {
  display: none;
}

.number_field:focus {
  outline: none !important;
  box-shadow: none !important;
}

.cost_calculator_estimated_cost {
  font-family: Futura PT;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  color: #3e3e3e !important;
  text-align: center;
}

.add_location_calculator,
.add_calendar_calculator {
  background: #ffffff;
  padding: 10px;
  background-color: #f4f4f4;
  border-radius: 5px;
  border: none;
  width: 100%;
  height: 90%;
}

.add_calendar_calculator {
  height: 50px;
}

.label_calculator,
.label_calculator_SBUA,
.label_calculator_custom_pricing {
  font-family: "fm";
  line-height: 31px;
  font-style: normal;
  font-weight: 450;
  font-size: 24px;
  color: #3e3e3e;
}

.date {
  padding: 25px;
}

.label_calculator {
  font-size: 20px;
  color: #000000;
}

.row_calculator {
  margin-top: 20px;
}

.fa-map-marker-alt,
.fa-calendar-alt {
  color: #fdc315;
  margin-right: 10px;
}

.cal_home_icons {
  font-size: 25px;
  /* margin: 15px; */
  color: #000;
}

.spec_name {
  font-family: "fm";
  font-style: normal;
  font-weight: 450;
  font-size: 18px;
  line-height: 26px;
  color: #3e3e3e;
}

#btn_car {
  width: 80%;
}

.btn_fill {
  background: #f4f4f4 !important;
  /* border: 1px solid #000; */
}

.btn_increment {
  background: none !important;
  font-size: 18px;
  font-family: "fm";
  justify-content: center;
  display: flex;
  border: none;
  border-radius: 50%;
  align-items: center;
  width: 22px;
  height: 22px;
}

.btn_increment:hover {
  background: #fdc315;
  border: none;
}

.background_all {
  width: 50px;
  height: 50px;
  background: #f4f4f4;
  border-radius: 5px;
}

.amenties-name {
  font-size: 14px;
}
.quantity {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
}
.quantity__minus,
.quantity__plus {
  display: block;
  width: 22px;
  height: 23px;
  margin: 0;
  color: #000 !important;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 40px !important;
  border: 1px solid #000;
  background: #fff;
  text-decoration: none;
  text-align: center;
  line-height: 23px;
}
.quantity__minus:hover,
.quantity__plus:hover {
  background: #fdc315;
  color: #fff;
}
.quantity__minus {
  border-radius: 3px 0 0 3px;
}
.quantity__plus {
  border-radius: 0 3px 3px 0;
}
.quantity__input {
  width: 32px;
  height: 19px;
  margin: 0;
  padding: 0;
  text-align: center;
  border: none;
  background: #fff;
  color: #8184a1;
}
.quantity__minus:link,
.quantity__plus:link {
  color: #8184a1;
}
.quantity__minus:visited,
.quantity__plus:visited {
  color: #000;
  /* background:yellow */
}
.quantity2 {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
}
.quantity__minus2,
.quantity__plus2 {
  display: block;
  width: 22px;
  height: 23px;
  margin: 0;
  color: #000 !important;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 40px !important;
  border: 1px solid #000;
  background: #fff;
  text-decoration: none;
  text-align: center;
  line-height: 23px;
}
.quantity__minus2:hover,
.quantity__plus2:hover {
  background: #fdc315;
  color: #fff;
}
.quantity__minus2 {
  border-radius: 3px 0 0 3px;
}
.quantity__plus2 {
  border-radius: 0 3px 3px 0;
}
.quantity__input2 {
  width: 32px;
  height: 19px;
  margin: 0;
  padding: 0;
  text-align: center;
  border: none;
  background: #fff;
  color: #8184a1;
}
.quantity__minus2:link,
.quantity__plus2:link {
  color: #8184a1;
}
.quantity__minus2:visited,
.quantity__plus2:visited {
  color: #000;
  /* background:yellow */
}
.quantity3 {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
}
.quantity__minus3,
.quantity__plus3 {
  display: block;
  width: 22px;
  height: 23px;
  margin: 0;
  color: #000 !important;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 40px !important;
  border: 1px solid #000;
  background: #fff;
  text-decoration: none;
  text-align: center;
  line-height: 23px;
}
.quantity__minus3:hover,
.quantity__plus3:hover {
  background: #fdc315;
  color: #fff;
}
.quantity__minus3 {
  border-radius: 3px 0 0 3px;
}
.quantity__plus3 {
  border-radius: 0 3px 3px 0;
}
.quantity__input3 {
  width: 32px;
  height: 19px;
  margin: 0;
  padding: 0;
  text-align: center;
  border: none;
  background: #fff;
  color: #8184a1;
}
.quantity__minus3:link,
.quantity__plus3:link {
  color: #8184a1;
}
.quantity__minus3:visited,
.quantity__plus3:visited {
  color: #000;
  /* background:yellow */
}
.quantity4 {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
}
.quantity__minus4,
.quantity__plus4 {
  display: block;
  width: 22px;
  height: 23px;
  margin: 0;
  color: #000 !important;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 40px !important;
  border: 1px solid #000;
  background: #fff;
  text-decoration: none;
  text-align: center;
  line-height: 23px;
}
.quantity__minus4:hover,
.quantity__plus4:hover {
  background: #fdc315;
  color: #fff;
}
.quantity__minus4 {
  border-radius: 3px 0 0 3px;
}
.quantity__plus4 {
  border-radius: 0 3px 3px 0;
}
.quantity__input4 {
  width: 32px;
  height: 19px;
  margin: 0;
  padding: 0;
  text-align: center;
  border: none;
  background: #fff;
  color: #8184a1;
}
.quantity__minus4:link,
.quantity__plus4:link {
  color: #8184a1;
}
.quantity__minus4:visited,
.quantity__plus4:visited {
  color: #000;
  /* background:yellow */
}

.quantity5 {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
}
.quantity__minus5,
.quantity__plus5 {
  display: block;
  width: 22px;
  height: 23px;
  margin: 0;
  color: #000 !important;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 40px !important;
  border: 1px solid #000;
  background: #fff;
  text-decoration: none;
  text-align: center;
  line-height: 23px;
}
.quantity__minus5:hover,
.quantity__plus5:hover {
  background: #fdc315;
  color: #fff;
}
.quantity__minus5 {
  border-radius: 3px 0 0 3px;
}
.quantity__plus5 {
  border-radius: 0 3px 3px 0;
}
.quantity__input5 {
  width: 32px;
  height: 19px;
  margin: 0;
  padding: 0;
  text-align: center;
  border: none;
  background: #fff;
  color: #8184a1;
}
.quantity__minus5:link,
.quantity__plus5:link {
  color: #8184a1;
}
.quantity__minus5:visited,
.quantity__plus5:visited {
  color: #000;
  /* background:yellow */
}

.quantity6 {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
}
.quantity__minus6,
.quantity__plus6 {
  display: block;
  width: 22px;
  height: 23px;
  margin: 0;
  color: #000 !important;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 40px !important;
  border: 1px solid #000;
  background: #fff;
  text-decoration: none;
  text-align: center;
  line-height: 23px;
}
.quantity__minus6:hover,
.quantity__plus6:hover {
  background: #fdc315;
  color: #fff;
}
.quantity__minus6 {
  border-radius: 3px 0 0 3px;
}
.quantity__plus6 {
  border-radius: 0 3px 3px 0;
}
.quantity__input6 {
  width: 32px;
  height: 19px;
  margin: 0;
  padding: 0;
  text-align: center;
  border: none;
  background: #fff;
  color: #8184a1;
}
.quantity__minus6:link,
.quantity__plus6:link {
  color: #8184a1;
}
.quantity__minus6:visited,
.quantity__plus6:visited {
  color: #000;
  /* background:yellow */
}

.cost_cal_packages {
  font-family: "fm";
  font-style: normal;
  font-weight: 450;
  font-size: 24px;
  color: #3e3e3e;
  line-height: 31px;
}

.cost_cal_packages_select {
  background-color: #f4f4f4;
  border-radius: 5px;
  width: 100% !important;
  border: none !important;
}

.btn_packages {
  padding-top: 1.5rem;
}

.cost_cal_packages_select:focus {
  outline: none !important;
}

.cost_cal_packages_select:hover {
  background-color: #ecbf26 !important;
}

/* .cost_cal_packages_select {
    background-color: #ECBF26;
} */

#package2 {
  background-color: #ecbf26;
}

.package_names {
  font-size: 15px;
  font-family: "fm";
  font-style: normal;
  font-weight: 450;
  line-height: 17px;
  color: #a7a7a7;
}

.cal_cost_rate {
  font-family: "FTPD";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  color: #000000;
  line-height: 31px;
}

.span_SBUA {
  font-family: "fm";
  font-style: normal;
  font-weight: 450;
  font-size: 10px;
  color: #000000;
  line-height: 13px;
}

.range_value_sqrt {
  font-family: Futura PT;
  font-style: normal;
  font-weight: 600;
  font-size: 26px;
  color: #000000;
}

.label_number {
  font-family: Futura PT;
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 41px;
  color: gary;
}

.custom_pricing_SBUA {
  font-family: Futura PT;
  font-style: normal;
  font-weight: 450;
  font-size: 14px;
  color: #000000;
}

.strike {
  display: block;
  text-align: center;
  overflow: hidden;
  white-space: nowrap;
}

.strike > span {
  position: relative;
  display: inline-block;
}

.strike > span:before,
.strike > span:after {
  content: "";
  position: absolute;
  top: 50%;
  width: 9999px;
  height: 1px;
  background: lightgray;
}

.popup_details {
  margin-bottom: 0px !important;
}

.strike > span:before {
  right: 100%;
  margin-right: 15px;
}

.strike > span:after {
  left: 100%;
  margin-left: 15px;
}

.btn_cost_calculator {
  width: 100%;
  background: #fdc315;
  border-radius: 5px;
  border: none;
}

/* thank you popup */
.thankyou_main {
  margin-top: 7%;
  background: #f9f9f9;
  padding-top: 5%;
  padding-bottom: 5%;
}
#modalForm2 {
  z-index: 999999999;
}

.btn-close:focus {
  box-shadow: none !important;
}

.keep_browsing {
  background: linear-gradient(94.48deg, #ffe185 -2.3%, #f7ba00 139.95%);
  border-radius: 5px;
  border: none;
  padding: 15px 23px;
  font-family: FTPD;
}
.thankyou_heading {
  font-size: 30px;
  color: #000;
  font-weight: bold;
  font-family: "fb";
}
.thank_subheading {
  color: #969696;
  font-size: 18px;
  font-family: "fm";
}
.thank_line {
  background-color: #fdc315;
  height: 3px !important;
  width: 50%;
}
.cls_btn {
  position: absolute;
  right: 0;
  padding: 10px;
  z-index: 9999;
}

/* For Mobile Devices */
@media only screen and (min-width: 320px) and (max-width: 480px) {
  /* Nav Bar */
  .navbar-brand img {
    width: 100px;
  }
  /* .nav_container {
    padding-left: 0px;
    padding-right: 0px;
  } */
  .land_nav_ul > li + li {
    border-left: none;
  }

  .land_nav_toggle {
    padding-top: 2rem;
  }

  .land_nav_ul li {
    padding-top: 10px;
  }

  .navbar_li_div {
    padding-bottom: 10px;
  }

  /* Present in */
  .land_present {
    height: auto;
    margin-top: 0%;
  }

  .land_present_arrow_btn {
    width: 35px;
    height: 29px;
  }

  /* Land Feed Back */
  .land_cust_feed_head_txt {
    font-size: 26px;
    line-height: 1;
  }
  .cust_love_icon {
    font-size: 27px !important;
  }
  .land_cust_feed_sub_txt {
    padding-bottom: 30px;
  }
  .land_cust_feedback {
    padding-top: 15px;
  }

  .mainHappyCustomerBox {
    padding: 15px !important;
  }

  .mainHappyCustomerBox .customerImage {
    padding-bottom: 15px;
  }
  .mainHappyCustomerBox p {
    line-height: 1.2 !important;
    font-size: 14px;
    min-height: inherit;
  }
  .mainHappyCustomerBox h4 {
    padding-top: 15px;
    font-size: 17px;
  }

  .land_feedback_arrow_prev,
  .land_feedback_arrow_next {
    width: 8%;
  }

  /* FAQ */
  .land_faq_head_txt {
    font-size: 27px;
    padding-bottom: 0.5rem !important;
  }
  .accordion .card-header button {
    line-height: 1.2;
    font-size: 17px;
    padding: 17px 58px 17px 25px;
  }
  .accordion button .icon {
    right: 16px;
  }
  .accordion .card-body {
    padding: 30px 25px 10px 25px;
    font-size: 15px;
  }

  /* Footer */
  .listContactInfo {
    padding-left: 0px;
  }

  .rowMainFooter {
    padding: 40px 0px;
  }
  .footer {
    font-size: 15px;
  }
  ul.socialIcons {
    font-size: 18px;
    margin-bottom: 0px;
  }

  ul.socialIcons li + li {
    margin-left: 10px;
  }

  .rowMainFooter [class*="col"] + [class*="col"] {
    margin-top: 30px;
  }

  /* Cost Calculator */
  .calculated_value {
    text-align: center;
    padding: 10px;
  }

  .btn_get_specs {
    padding: 10px;
  }

  .spec_name {
    font-size: 13px;
  }

  .cost_cal_packages_select {
    height: 100%;
    padding: 5px;
  }
  .cal_cost_rate {
    font-size: 20px;
  }

  .btn_packages .col-4 {
    padding-left: 4%;
    padding-right: 0px;
  }

  .btn_packages {
    padding-top: 1.6rem;
  }

  .talk-bubble {
    top: 72.6%;
    width: 100px;
  }

  /* darshan */
  /* button */
  .mob_cal_btn {
    display: block;
    position: fixed;
    z-index: 100000;
    bottom: 20px;
    /* margin-left: auto; */
    margin-left: 55px;
  }

  /* Banners */
  .contentHomeDesign {
    margin-bottom: 40px;
  }
  .sliding_comp {
    margin-top: 23%;
  }

  .top_banners_ {
    width: 100%;
  }
  .text_overlap {
    background-color: black;
    max-width: 100%;
    text-align: center;
    padding: 20px 0px;
    border-radius: 0px;
  }
  .text_overlap h3 {
    font-size: 26px;
  }
  .overlap_section {
    width: unset !important;
    position: initial;
  }
  .btnWhite {
    font-size: 11px !important;
    padding: 0px 20px !important;
    height: 50px !important;
    line-height: 50px !important;
    min-width: 0px;
  }
  .mob_cal_btn {
    display: block;

    position: fixed;
    z-index: 100000;
    bottom: 20px;
    /* margin-left: auto; */
    margin-left: 55px;
  }
  .designBuilding h4 {
    font-size: 27px;
    margin-bottom: 20px;
    text-align: center;
  }
  .why_ppl_choose {
    padding: 0px;
  }
  .heading-center h2 {
    font-size: 27px;
  }
  .sub-heading {
    margin-bottom: 30px;
    font-size: 17px;
  }
  .sub-heading1 {
    font-size: 17px;
  }
  .first_sec_form {
    position: initial;
    width: 100%;
  }
  .rowPeoplechoose .row [class*="col"] .contentChoose {
    padding-top: 60px;
    margin-top: 40px;
    border-top: solid 1px #e3e1e1;
  }
  .sizeDetail .row + .row {
    margin-top: 0px;
  }
  .sizeDetail .row [class*="col"] {
    max-width: 100%;
    flex: 0 0 100%;
    padding: 3px 15px;
  }

  .btnLoadMore {
    padding: 30px 0px 0px 0px;
  }

  .accordion {
    padding-bottom: 0px;
  }

  .sectionCommanInner.sectionHappyCustomerInner {
    padding: 50px 0px;
  }

  .customdesign_banner {
    padding: 50px 0px;
  }

  .customdesign_banner h3 {
    font-size: 29px;
  }

  .customdesign_banner .btn {
    height: 50px;
    line-height: 50px;
    font-size: 15px;
    padding: 0px 20px;
  }

  /* floor */
  .bloc-tabs {
    flex-wrap: wrap;
  }

  #carouselExampleSlidesOnly1 {
    display: block;
  }
  #carouselExampleSlidesOnly1 h5 {
    margin-top: 1.5rem;
  }
  .thankyou_main {
    margin-top: 24%;
  }
  .costcal_comp {
    margin-top: 22%;
    background: #f9f9f9;
    padding-top: 5%;
  }
  .contentHomeDesign {
    margin-bottom: 40px;
  }
  .sliding_comp {
    margin-top: 22% !important;
  }
  .boxLoadMore {
    margin-top: 0px !important;
  }

  .btn_increment {
    width: 22px !important;
    height: 22px !important;
  }

  #btn_car {
    width: 100% !important;
  }
}

/* For iPads, Tablets Devices */
@media only screen and (min-width: 481px) and (max-width: 768px) {
  /* Nav Bar */
  .land_nav_ul > li + li {
    border-left: none;
  }

  .land_nav_toggle {
    padding-top: 2rem;
  }

  .land_nav_ul li {
    padding-top: 10px;
  }

  .navbar_li_div {
    padding-bottom: 10px;
  }

  /* Present in */
  .land_present {
    height: auto;
  }

  /* Land Feed Back */
  .land_cust_feedback {
    padding-top: 15px;
  }

  .mainHappyCustomerBox {
    padding: 15px !important;
  }

  .mainHappyCustomerBox .customerImage {
    padding-bottom: 15px;
  }
  .mainHappyCustomerBox p {
    line-height: 1.2 !important;
    font-size: 14px;
  }
  .mainHappyCustomerBox h4 {
    padding-top: 15px;
    font-size: 17px;
  }

  /* FAQ */
  .accordion .card-header button {
    line-height: 1.2;
    font-size: 19px;
    padding: 17px 65px 17px 25px;
  }
  .accordion button .icon {
    right: 16px;
  }
  .accordion .card-body {
    padding: 30px 25px 10px 25px;
  }

  /* Cost Caluclator */
  .first_sec_form {
    position: initial;
    width: 100%;
  }

  .heading_cost_cal {
    font-size: 30px;
  }

  .input-wrapper .col-md-5 {
    width: 36.666667%;
  }

  .input-wrapper .col-md-7 {
    width: 63.333333%;
  }

  .btn_get_quote {
    padding: 0px;
  }

  /* Darshan */
  /* Banner */
  .overlap_section {
    top: 35%;
    left: 5%;
    width: 80%;
  }

  .text_overlap {
    padding: 10px;
  }

  .text_overlap h3 {
    font-size: 26px;
  }

  .carousel_txt {
    /* margin-bottom: 0% !important; */
    width: 76%;
  }
  .talk-bubble {
    top: 74%;
    width: 170px;
  }

  .land_present {
    margin-top: 0%;
  }

  .why_ppl_choose {
    padding: 35px;
  }
  .sub-heading {
    margin-bottom: 30px;
  }

  .bgYellowDesign {
    padding: 40px 0px;
  }

  .designBuilding h4 {
    font-size: 27px;
  }

  .btnWhite {
    padding: 0px 30px !important;
  }

  .boxLoadMore > .row + .row {
    margin-top: 0px !important;
  }
  .contentHomeDesign {
    margin-bottom: 35px !important;
  }
  .boxLoadMore {
    margin-top: 0px !important;
  }

  .btn_increment {
    width: 22px !important;
    height: 22px !important;
  }

  /* #btn_car {
    width: 100% !important;
  } */
}

@media only screen and (min-width: 390px) and (max-width: 767px) {
  .btn_increment {
    width: 22px !important;
    height: 22px !important;
  }
}

/* For Small screen Laptop Devices */
@media only screen and (min-width: 769px) and (max-width: 1024px) {
  .mainHappyCustomerBox {
    padding: 20px !important;
  }

  .mainHappyCustomerBox .customerImage {
    padding-bottom: 15px;
  }
  .mainHappyCustomerBox {
    line-height: 1.2;
    font-size: 14px;
  }
  .mainHappyCustomerBox h4 {
    padding-top: 15px;
    font-size: 17px;
  }
  .btn_increment {
    width: 22px !important;
    height: 22px !important;
  }
}

/* For Desktops, large screens Devices */
@media only screen and (min-width: 1025px) and (max-width: 1200px) {
  .btn_increment {
    width: 22px !important;
    height: 22px !important;
  }
}

/* ForDesktop or large-size screen Devices */
@media only screen and (min-width: 1201px) {
  .btn_increment {
    width: 22px !important;
    height: 22px !important;
  }
}
